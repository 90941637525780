import axios from "axios";
import {
  LoginMailPopUpType,
  RegistrationStepOneFormType,
  uploadBirthCertificateInformationType,
  uploadDocType,
  uploadParentInformationType,
  uploadParentPassportInformationType,
  uploadPersonalInformationType,
} from "../type/type";
import { instance } from "./axios";

export const registrationAPI = {
  getCost(classId: number, is_extended: boolean) {
    return instance.get(`get_cost?class_id=${classId}&is_extended=${is_extended}`);
  },
  getSchools() {
    return instance.get(`get_schools`);
  },
  getDisciplines(numberClass: number) {
    return instance.get(`get_class_disciplines?class_id=${numberClass}`);
  },
  registration(data: RegistrationStepOneFormType) {
    return instance.post(`register`, {
      class_id: data.classNumber,
      ...data,
    });
  },
  registrationOverwrite(data: RegistrationStepOneFormType) {
    return instance.put(`update_user_information`, {
      class_id: data.classNumber,
      ...data,
    });
  },
  uploadDocuments(data: uploadDocType) {
    const form = new FormData();
    form.append("type_of_files", data.type_of_files);
    //@ts-ignore
    form.append("category_id", data.category_id);

    data.files.forEach((item: any) => {
      form.append("files", item);
    });

    return instance.post(`upload_documents`, form);
  },

  uploadPersonalInformation(data: uploadPersonalInformationType) {
    let patronymic = "";
    if (data.patronymic) {
      patronymic = "&patronymic=" + data.patronymic;
    }

    return instance.post(
      `upload_personal_information?first_name=${data.first_name}&second_name=${data.second_name}&gender=${data.gender}&birth_date=${data.birth_date}&insurance_number=${data.insurance_number}&citizenship=${data.citizenship}${patronymic}`
    );
  },

  uploadBirthCertificateInformation(data: uploadBirthCertificateInformationType) {
    return instance.post(
      `upload_birth_certificate_information?series=${data.series}&number=${data.number}&date_of_issue=${data.date_of_issue}&issued_by=${data.issued_by}`
    );
  },

  uploadParentInformation(data: uploadParentInformationType) {
    let patronymic = "";
    if (data.patronymic) {
      patronymic = "&patronymic=" + data.patronymic;
    }

    return instance.post(
      `upload_parent_information?firstname=${data.firstname}&second_name=${data.second_name}&birth_date=${data.birth_date}&living_address=${data.living_address}&convenient_messenger=${data.convenient_messenger}${patronymic}`
    );
  },

  uploadParentPassportInformation(data: uploadParentPassportInformationType) {
    return instance.post(
      `upload_parent_passport_information?series=${data.series}&number=${data.number}&date_of_issue=${data.date_of_issue}&issued_by=${data.issued_by}&registration_address=${data.registration_address}&department_code=${data.department_code}&passport_type=${data.passport_type}`
    );
  },
};

export const authAPI = {
  authMe() {
    return instance.get("get_current_user");
  },
  login(data: LoginMailPopUpType) {
    return instance.post("login", {
      username: data.email,
      password: data.password,
    });
  },
  logout() {
    return instance.put("logout");
  },
};

export const bookAPI = {
  getMyBooksForDisciplines(id: number) {
    return instance.get(`books/get_my_books_for_discipline?discipline_id=${id}&offset=0&limit=2`);
  },
};

export const profileAPI = {
  getMyDoc() {
    return instance.get("get_my_documents");
  },
  getMyAssessments() {
    return instance.get("get_my_assessments_results");
  },
};

export const payAPI = {
  init({
    Amount,
    OrderId,
    Description,
    Token,
  }: {
    Amount: string;
    OrderId: string;
    Description: string;
    Token: string;
  }) {
    return axios.post(`https://securepay.tinkoff.ru/v2/Init`, {
      TerminalKey: "1715671967566",
      OrderId,
      Amount,
      Language: "ru",
      Description,
      Token,
    });
  },
  initBack({ PaymentId }: { PaymentId: string }) {
    return instance.post(`payments/init?payment_id=${PaymentId}`);
  },
};
export const documentAPI = {
  getAgreement() {
    return instance.get("get_agreement");
  },
  uploadDocuments(data: uploadDocType) {
    const form = new FormData();
    form.append("type_of_files", data.type_of_files);
    //@ts-ignore
    form.append("category_id", data.category_id);

    data.files.forEach((item: any) => {
      form.append("files", item);
    });

    return instance.post(`upload_documents`, form);
  },
};
export const lessonsApi = {
  getLessons(discipline_id: number) {
    return instance.get(`lessons/get_my_lessons?discipline_id=${discipline_id}`);
  },
  getLesson(lesson_id: number) {
    return instance.get(`lessons/get_lesson?lesson_id=${lesson_id}`);
  },
};

export const testsApi = {
  getTest(test_id: number) {
    return instance.get(`tests/get_test?test_id=${test_id}`);
  },
  completeTest(data: any) {
    return instance.post("tests/complete_test", data);
  },
  showResultTest(test_id: number) {
    return instance.get(`tests/show_test_results/?test_id=${test_id}`);
  },
  showLastResultTest(test_id: number) {
    return instance.get(`tests/get_my_last_result/?test_id=${test_id}`);
  },
};

export const disciplinesApi = {
  getDiscipline(id: number) {
    return instance.get(`disciplines/get_discipline?discipline_id=${id}`);
  },
};

export const contractsAPI = {
  getMyPersonalConsent() {
    return instance.get(`contracts/get_my_personal_consent`, {responseType: 'blob'});
  },
  getMyEnrollmentpatition() {
    return instance.get(`contracts/get_my_enrollment_petition`, {responseType: 'blob'});
  },
};

export const assessmentsApi = {};
